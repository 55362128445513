/* This file is for animations in CSS */

/* FADE IN */

.fadeIn {
  animation: fadeIn ease 200ms;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.scaleIn {
  animation: scaleIn ease 100ms;
}
@keyframes scaleIn {
  100% {
    transform: 1;
  }
}

/* LOADING SPINNER */

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid lightgray;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* BG LOOP COLOR */

.loopBgColor {
  animation: loopBgColor ease 10s;
  transition-duration: 50ms;
  animation-iteration-count: infinite;
}

@keyframes loopBgColor {
  0% {
    background-color: #a30965;
  }
  25% {
    background-color: #ce6aa7;
  }
  50% {
    background-color: #ff8a65;
  }
  75% {
    background-color: #a2abbe;
  }
  100% {
    background-color: #a30965;
  }
}

/* LOOP COLOR */

.loopColor {
  animation: loopColor ease 10s;
  transition-duration: 50ms;
  animation-iteration-count: infinite;
}

@keyframes loopColor {
  0% {
    color: #a30965;
  }
  25% {
    color: #ce6aa7;
  }
  50% {
    color: #ff8a65;
  }
  75% {
    color: #a2abbe;
  }
  100% {
    color: #a30965;
  }
}

/* LOOP SCALE */

.loopScale {
  animation: loopScale ease 10s;
  transition-duration: 50ms;
  animation-iteration-count: infinite;
}

@keyframes loopScale {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  10% {
    transform: scale(1.01);
    opacity: 1;
  }
  90% {
    transform: scale(1.01);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* HEART BEAT */

.heartBeat {
  animation: heartBeat ease 25s;
  animation-iteration-count: infinite;
}

@keyframes heartBeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

/* (NO DELAY) (PAUSE UNTIL 50%) FADE IN */

.fadeInNoDelay {
  animation: fadeInNoDelay ease;
}
@keyframes fadeInNoDelay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
