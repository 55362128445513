@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./animations.css";
@import "./datePickerCustom.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap");
@import "../electrify_frontend_common/components/Buttonv2/index.css";

.overlay {
  background-color: rgb(255, 255, 255, 0.05);
  /* 'rgb(206, 105, 167, .7)',
  'rgb(179, 179, 179, .7)',
  'rgb(131, 7, 81, .7)',
  'rgb(23, 22, 24, .7)', */
}

.overlay-white {
  background-color: rgb(255, 255, 255, 0.35);
  /* 'rgb(206, 105, 167, .7)',
  'rgb(179, 179, 179, .7)',
  'rgb(131, 7, 81, .7)',
  'rgb(23, 22, 24, .7)', */
}

.overlay-black {
  background-color: rgb(0, 0, 0, 0.07);
  /* 'rgb(206, 105, 167, .7)',
  'rgb(179, 179, 179, .7)',
  'rgb(131, 7, 81, .7)',
  'rgb(23, 22, 24, .7)', */
}

.overlay-Grey-5 {
  background-color: #f7f9fa;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blur {
  background: rgba(255, 255, 255, 0.5);
  /* -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px); */
}

.trans {
  transition: all 100ms ease;
}

.strictNoMarginTop {
  margin-top: 0 !important;
}

/* CUSTOM SCROLL CLASS */

/* NUMBER INNPUT ARROWS */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */

input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  position: relative;
  width: 50%;
  height: 2.5rem;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0 0 #0000;
  color: #374151;
  font-size: 1rem;
  line-height: 1.5;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  display: block;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #374151;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}


/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* PLACEHOLDER COLOR */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: lightgray !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: lightgray !important;
}

/* TABLE */

th {
  text-align: left;
}

/* tr:hover > td {
  background-color: #f7fafc;
} */

/* INPUT */

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #1da571;
}

.inputParent {
  border: none;
  /* border-bottom: 1px solid lightgray; */
  opacity: 0.8;
}

.inputParent:focus-within {
  border: none;
  border-bottom: 1px solid gray !important;
}

/* REACT-DATES */

.CalendarDay__selected_span {
  border: none;
  background: #db8fbe;
  color: white;
}

.CalendarDay__selected_span:hover {
  border: none;
  background: #db8fbe;
}

.CalendarDay__selected {
  border: none;
  background: #830751;
  color: white;
}

.CalendarDay__selected:hover {
  border: none;
  background: #830751;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  border: none;
  background: #db8fbe;
}

.DateRangePickerInput__withBorder {
  border: none;
  border: 1px solid #e4e4e7;
  border-radius: 4px;
}

.DateRangePickerInput_calendarIcon {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.DateInput_input {
  border: none !important;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.DateInput_input:focus {
  border: none !important;
  font-size: 1rem;
}
.DateInput_input__focused:focus {
  border: none !important;
  font-size: 1rem;
}
.DateInput_input__focused {
  border: none !important;
  font-size: 1rem;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: #595E69;
  max-width: 240px;
}

.leaflet-popup-tip-container {
  display: none !important;
}

.leaflet-popup-close-button {
  display: none !important;
}

.btn {
  @apply rounded text-sm
}

.btn-primary {
  @apply
  bg-Blueberry-dark-default border border-white text-white
  hover:bg-Blueberry-light-default 
  active:bg-Blueberry-dark-shade  
  disabled:bg-Grey-tint disabled:text-Grey-dark
}
  
.btn-secondary {
  @apply  border border-Blueberry-dark-default bg-white text-Blueberry-dark-default
  hover:bg-Blueberry-light-secondary-hover
  active:bg-Blueberry-light-default active:text-white
  disabled:bg-Grey-tint disabled:text-Grey-dark disabled:border-Grey-dark
}

.btn-tertiary {
  @apply  border border-Grey-tint bg-white text-Grey-dark
  hover:bg-Grey-tint
  active:bg-Grey-default active:border-Grey-default active:text-white
  disabled:bg-Grey-tint disabled:text-Grey-default disabled:border-Grey-tint
}

.btn-link {
  @apply  bg-white border border-white text-Blueberry-dark-default
  hover:bg-Blueberry-light-link-hover border-Blueberry-light-link-hover
  active:bg-Blueberry-light-secondary-hover active:border-Blueberry-light-secondary-hover
  disabled:bg-Grey-tint disabled:text-Grey-default disabled:border-Grey-tint 
}

.btn-large {
  @apply px-12 py-5 text-lg leading-none
}

.btn-medium {
  @apply px-8 py-4 
}

.btn-small {
  @apply px-4 py-2 text-sm
}