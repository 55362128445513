.btn {
    @apply rounded text-sm
}

.btn-primary {
    @apply
    bg-Blueberry-dark-default border border-Blueberry-dark-default text-white
    hover:bg-Blueberry-light-default 
    focus:bg-Blueberry-dark-shade  
    disabled:bg-Grey-tint disabled:text-Grey-dark
}
    
.btn-secondary {
    @apply  border border-Blueberry-dark-default bg-white text-Blueberry-dark-default
    hover:bg-Blueberry-light-secondary-hover
    focus:bg-Blueberry-light-default focus:text-white
    disabled:bg-Grey-tint disabled:text-Grey-dark disabled:border-Grey-dark
}

.btn-tertiary {
    @apply  border border-Grey-tint bg-white text-Grey-dark
    hover:bg-Grey-tint
    focus:bg-Grey-default focus:border-Grey-default focus:text-white
    disabled:bg-Grey-tint disabled:text-Grey-default disabled:border-Grey-tint
}

.btn-link {
    @apply  bg-white border border-white text-Blueberry-dark-default
    hover:bg-Blueberry-light-link-hover border-Blueberry-light-link-hover
    focus:bg-Blueberry-light-secondary-hover focus:border-Blueberry-light-secondary-hover
    disabled:bg-Grey-tint disabled:text-Grey-default disabled:border-Grey-tint 
}

.btn-large {
    @apply px-10 py-5
}

.btn-medium {
    @apply px-8 py-4 
}

.btn-small {
    @apply px-4 py-2
}